@page {
    size: A4;
    margin: 0;
}

@font-face {
    font-family:  "DejaVu Sans";
    src:  url('./assets/DejaVuSans.ttf') format("truetype");
}

@font-face {
    font-family:  "DejaVu Sans";
    font-weight:  bold;
    src:  url('./assets/DejaVuSans-Bold.ttf') format("truetype");
}

@font-face {
    font-family:  "DejaVu Sans";
    font-style:  italic;
    src:  url('./assets/DejaVuSans-Oblique.ttf') format("truetype");
}

@font-face {
    font-family:  "DejaVu Sans";
    font-weight:  bold;
    font-style:  italic;
    src:  url('./assets/DejaVuSans-Oblique.ttf') format("truetype");
}

.text {
    font-family: "DejaVu Sans", "Arial", sans-serif;
}

.a4-paper {
    word-wrap: break-word;
    font-family: "DejaVu Sans", "Arial", sans-serif;
    font-size: 12px;
    width: 210mm;
    height: 297mm;
}

.a4-paper-cards {
    word-wrap: break-word;
    font-family: "DejaVu Sans", "Arial", sans-serif;
    font-size: 15px;
    width: 210mm;
    height: 297mm;
    display: block;
}

.a6-paper {
    word-wrap: break-word;
    font-family: "DejaVu Sans", "Arial", sans-serif;
    font-size: 12px;
    width: 207.60mm;
    height: 74.25mm;
}

.a6-wrapper-content {
    text-align: center;
}

.a6-wrapper-content-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5mm;
}

.wrapper {
    margin-top: 5mm;
    width: 210mm;
    overflow: hidden;
    display: flex;
}

.wrapper-content {
    margin: 10mm 15mm;
    width: 180mm;
    overflow: hidden;
    float: left;
}

.wrapper-content-no-top {
    margin-left: 15mm;
    margin-right: 15mm;
    width: 180mm;
    overflow: hidden;
    float: left;
}

.wrapper-tbl {
    margin-top: 2mm;
    width: 210mm;
    overflow: hidden;
    display: flex;
}

.column-3 {
    width: 70mm;
    float:left;
}

.column-2 {
    width: 80mm;
    float:left;
    margin-left: 25mm;
}

.column-2-1 {
    width: 80mm;
    float:left;
    margin-left: 25mm;
}

.column-2-2 {
    width: 80mm;
    float:left;
    margin-left: 1mm;
}

.column-3-s {
    width: 55mm;
    float:left;
   margin-left: 27mm;
}

.column-3-s-c {
    width: 20mm;
    float:left;
    margin-left: 27mm;
}

.column-3-s-r {
    width: 40mm;
    float:left;
}

.center {
    justify-content: center;
    text-align: center;
}

.leftMargin-100 {
    margin-left: 100mm;
    float:left;
}

.line-break {
    width: 100%;
}

.stamp {
    margin-top: 5mm;
    font-size: larger;
    font-weight: bold;
}

.tables {
    margin-top: 2mm;
}

.mt-top {
    margin-top: 10mm;
}

.mt-top-more {
    margin-top: 20mm;
}

.center-text {
    text-align: center;
}

.cards-margin-left {
    margin-left: 32.25mm;
}

.card-1 {
    display: flex;
    margin-bottom: 5mm;
}

.card-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 2mm;
}

.card-3 {
    text-align: center;
}

.mt-top-card {
    margin-top: 6.25mm;
}

.ml-left-card-1 {
    margin-left: 8mm;
}

.mt-top-card-whole-first {
    /* margin-top: 46mm; */
    height: 99mm;
    width: 210mm;
    overflow: hidden;
    display: flex;
}

.mt-top-card-whole-second {
    /* margin-top: 85mm; */
    height: 99mm;
    width: 210mm;
    overflow: hidden;
    display: flex;
}


